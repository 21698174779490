<template>
    <div>
        <b-row>
            <b-col md="12">
                <b-table bordered striped small show-empty :fields="questionFields" :items="questions"
                    :empty-text="this.$t('there_are_no_records')"></b-table>
            </b-col>
            <b-col md="12" class="mt-4">
                <multi-selectbox :options="commentQuestionOptions" :multiple="false" v-model="commentQuestion"
                    :placeholder="$t('survey_comment_question_select')" />
            </b-col>
            <b-col md="12" class="mt-4">
                <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                    :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                    @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                    v-show="datatable.showTable" />
            </b-col>
        </b-row>
    </div>
</template>
<script>

import SurveyService from "@/services/SurveyService";
import qs from "qs";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import Datatable from "@/components/datatable/Datatable";


export default {
    props: {
        detailData: {
            type: Object
        }
    },

    components: {
        MultiSelectbox,
        Datatable
    },
    data() {
        return {
            questionFields: [
                {
                    key: 'question',
                    label: this.$t('survey_question_about_course'),
                    sortable: false
                },
                {
                    key: 'instructor_avg',
                    label: this.$t('survey_staff_avg'),
                    sortable: false
                },
                {
                    key: 'faculty_avg',
                    label: this.$t('survey_faculty_avg'),
                    sortable: false
                },
                {
                    key: 'university_avg',
                    label: this.$t('survey_university_avg'),
                    sortable: false
                },
            ],

            questions: [
                {
                    question: "Test sorusu.",
                    avg1: "1",
                    avg2: "2",
                    avg3: "3"
                }
            ],

            commentQuestionOptions: [],
            commentQuestion: null,

            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: this.$t("id"),
                        field: "id",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("comment_question"),
                        field: "value",
                        sortable: false,
                    }
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: true,
                queryParams: {
                    filter: {
                        survey_id: this.$route.params.surveyId
                    },
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        }
    },

    watch: {
        detailData(value) {
            this.getResults(value)
            this.getCommentReport(value)
        },
        commentQuestion: {
            handler(val) {
                if (!val) {
                    this.datatable.rows = []
                    return
                }
                this.datatable.queryParams.page = 1
                this.datatable.queryParams.limit = 20
                this.getQuestionAnswer({ ...this.detailData, questionId: val })
            },
            immediate: true
        }
    },
    mounted() {
        this.datatable.rows = []
        this.getQuestionAnswer({ ...this.detailData, questionId: this.commentQuestion })
        this.getResults(this.detailData)
        this.getCommentReport(this.detailData)
    },
    methods: {
        async getQuestionAnswer(data) {
            if (!data?.filter?.survey_id) {
                return
            }
            const config = {
                params: {
                    filter: {
                        survey_id: data.filter.survey_id,
                        section_id: data.section_id,
                        semester_id: data.filter.semester_id,
                        question_id: data.questionId,
                    },
                    limit: this.datatable.queryParams.limit,
                    page: this.datatable.queryParams.page,
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            }

            const response = await SurveyService.commentAnswerReport(config).catch(e => {
                this.showErrors(e)
            })

            if (response.data.success) {
                this.datatable.rows = response.data.data
                this.datatable.total = response.data.pagination.total
            }
        },
        async getCommentReport(data) {
            if (!data?.filter?.survey_id) {
                return
            }
            const config = {
                params: {
                    filter: {
                        survey_id: data.filter.survey_id,
                        section_id: data.section_id,
                        semester_id: data.filter.semester_id,
                    }
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            }

            const response = await SurveyService.commentAnswerReport(config).catch(e => {
                this.showErrors(e)
            })

            if (response.data.success) {
                this.commentQuestionOptions = response.data.data.map(item => ({ text: item.value, value: item.id }))
            }
        },
        getResults(data) {
            const config = {
                params: {
                    filter: {
                        survey_id: data.filter.survey_id,
                        section_id: data.section_id,
                        semester_id: data.filter.semester_id,
                    }
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            }

            SurveyService.answerReport(config).then(response => {
                this.questions = response.data.data.map(item => ({
                    question: item.question,
                    instructor_avg: item.instructor_avg,
                    faculty_avg: item.faculty_avg,
                    university_avg: item.university_avg,
                }))
            }).catch(e => {
                this.showErrors(e)
            })
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getQuestionAnswer({ ...this.detailData, questionId: this.commentQuestion });
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getQuestionAnswer({ ...this.detailData, questionId: this.commentQuestion });
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getQuestionAnswer({ ...this.detailData, questionId: this.commentQuestion });
        },
    }
}
</script>
